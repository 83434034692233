@font-face {
  font-family: "LBet STD MT";
  src: url("./fonts/LBet\ STD\ MT\ Medium.ttf") format("opentype");
  unicode-range: U+10D0-10F0;
}

@font-face {
  font-family: "LBet STD Nus";
  src: url("./fonts/LBet\ STD\ Nus\ Medium.ttf") format("opentype");
  unicode-range: U+10D0-10F0;
}

@font-face {
  font-family: "LBet STD Nus Regular";
  src: url("./fonts/LBet\ STD\ Nus\ Regular.ttf") format("opentype");
  unicode-range: U+10D0-10F0;
}

@font-face {
  font-family: "LBet Banner Mt";
  src: url("./fonts/LBet\ Banner\ Mt\ Regular.ttf") format("opentype");
  unicode-range: U+10D0-10F0;
}

@font-face {
  font-family: "Sofia Pro Regular";
  src: url("./fonts/Sofia\ Pro\ Regular\ Az.otf") format("opentype");
}

@font-face {
  font-family: "Sofia Pro Medium";
  src: url("./fonts/Sofia\ Pro\ Medium\ Az.otf") format("opentype");
}

@font-face {
  font-family: "Sofia Pro Bold";
  src: url("./fonts/Sofia\ Pro\ Bold\ Az.otf") format("opentype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  background-color: #151b28;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Calendar styles */

.MuiPickersToolbar-toolbar {
  background-color: #171f32 !important;
  font-family: var(--font-family-1) !important;
}

.MuiPickersToolbar-toolbar span * {
  font-family: var(--font-family-1) !important;
}

.MuiPickersBasePicker-container {
  background-color: #171f32 !important;
}

.MuiDialogActions-root,
.MuiPickersCalendarHeader-iconButton {
  background-color: #171f32 !important;
}

.MuiDialogActions-root button {
  width: 100px;
  height: 40px !important;
}

.MuiDialogActions-root button:hover {
  background: transparent !important;
}

.MuiDialogActions-root button:nth-of-type(2) {
  background-color: #f1c157 !important;
}

.MuiDialogActions-root button:nth-of-type(1) {
  border: 2px solid #fff;
  box-sizing: border-box !important;
}

.MuiPickersCalendarHeader-iconButton svg {
  fill: #fff !important;
}

.MuiDialogActions-root button span,
.MuiPickersCalendarHeader-transitionContainer p,
.MuiPickersCalendarHeader-daysHeader span {
  color: #fff !important;
  font-family: var(--font-family-1) !important;
}

.MuiPickersYear-root.MuiTypography-subtitle1,
.MuiPickersMonth-root,
.MuiPickersDay-day p {
  color: #6f7fa4 !important;
  font-family: var(--font-family-1) !important;
}

.MuiPickersYear-yearSelected,
.MuiPickersMonth-monthSelected,
.MuiPickersDay-daySelected p {
  color: #fff !important;
}

.MuiPickersDay-daySelected {
  background-color: #f1c157 !important;
}

.MuiPaper-root.MuiDialog-paper.MuiPickersModal-dialogRoot {
  background-color: transparent !important;
}

.MuiPickersYearSelection-container::-webkit-scrollbar {
  width: 10px;
}

.MuiPickersYearSelection-container::-webkit-scrollbar-track {
  background: transparent;
}

.MuiPickersYearSelection-container::-webkit-scrollbar-thumb {
  background: #6f7fa4;
  border-radius: 10px;
}

.MuiPickersYearSelection-container::-webkit-scrollbar-thumb:hover {
  background: #fff;
}

:root {
  --font-family-1: "LBet STD MT", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  --font-family-2: "LBet STD Nus", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  --font-family-3: "LBet STD Nus Regular", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  --font-family-4: "LBet Banner Mt", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}
